.services {
  padding: 60px 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.services h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #333;
}

.services p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666;
}

.services .service {
  margin: 20px 0;
}

/* Media Queries for Services.css */
@media only screen and (max-width: 600px) {
  .services {
    padding: 30px 10px;
  }

  .services h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
  }

  .services p {
    font-size: 1em;
    margin-bottom: 15px;
  }

  .services .service {
    margin: 15px 0;
  }
}
