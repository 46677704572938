html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.footer {
  background-color: #001f3f;
  color: white;
  text-align: center;
  padding: 10px 20px;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.8rem;
  line-height: 1.4;
  box-sizing: border-box;
}
